import {
  Auth,
  AuthError,
  User,
  UserCredential,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAdminLogin } from '@core/services/networkService/_admin';

import { demmiRequest } from '@helpers/app.helper';

import { getDemmiAuth } from '../firebase';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

export interface AuthProviderProps {
  userData: User | null;
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>({} as UserContextState);

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};

export interface AuthContextModel {
  auth: Auth;
  fbAuthUser: User | null;
  user: User | null;
  isCheckingUser: boolean;
  signIn: (email: string, password: string) => Promise<UserCredential | void>;
  logOut: (onLogout?: () => void) => void;
}

export const AuthContext = createContext<AuthContextModel>({} as AuthContextModel);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export const AuthProvider = ({ userData, children }: AuthProviderProps): JSX.Element => {
  const [fbAuthUser, setFBAuthUser] = useState<User | null>(userData);
  const [user, setUser] = useState<User | null>(null);
  const [isCheckingUser, setIsCheckingUser] = useState(true);
  const navigate = useNavigate();

  const signIn = (email: string, password: string): Promise<UserCredential | void> => {
    return signInWithEmailAndPassword(getDemmiAuth(), email, password)
      .then(userCredential => {
        return fetchUser(userCredential.user)
          .then(_ => {})
          .catch(e => Promise.reject(e));
      })
      .catch((error: AuthError) => {
        // throw typeof error === 'string' ? error : prettifyAuthError(error);
        return Promise.reject(typeof error === 'string' ? error : DemmiFS.prettifyAuthError(error));
      });
  };

  const logOut = (onLogout?: () => void) => {
    signOut(getDemmiAuth()).then(() => {
      setUser(null);
      if (onLogout) onLogout();
      navigate('/', { replace: true });
    });
  };

  const fetchUser = (u: User | null) => {
    Logger({ objs: { u } }, fetchUser);

    if (u) {
      setIsCheckingUser(true);
      return demmiRequest(getAdminLogin(u))
        .then(e => {
          if (e === true) {
            Logger({ objs: { e } }, fetchUser);
            setUser(u);
          }
          setIsCheckingUser(false);
          return Promise.resolve(e);
        })
        .catch(e => {
          Logger({ objs: { e }, type: DemmiLogType.error }, fetchUser);
          setIsCheckingUser(false);
          return Promise.reject(e);
        });
    } else {
      setUser(null);
      setIsCheckingUser(false);
      return Promise.resolve(null);
    }
  };

  useEffect(() => {
    if (fbAuthUser) fetchUser(fbAuthUser).catch(() => {});
    else setIsCheckingUser(false);
  }, [fbAuthUser]);

  useEffect(() => {
    const unsubscribe = getDemmiAuth().onAuthStateChanged(u => {
      if (u) setFBAuthUser(u);
    });
    return unsubscribe;
  }, []);

  const values = useMemo(
    () => ({
      auth: getDemmiAuth(),
      fbAuthUser,
      user,
      isCheckingUser,
      signIn,
      logOut,
    }),
    [user, isCheckingUser],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
