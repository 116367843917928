import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AsyncImage from '@components/asyncImage/AsyncImage';

import { RouteParams } from '@core/services/routingService/routingService';
import DemmiCard from '@demmi-ui/Card/Card';
import { demmiRequest } from '@helpers/app.helper';

import VendorContactInfo from './partials/_vendorContactInfo';
import VendorDescription from './partials/_vendorDescription';
import VendorShop from './partials/_vendorShop';
import { getVendor } from '@core/services/networkService/_vendors';
import { DemmiFS } from '@subhanhabib/demmilib';

const VendorDetails: React.FC = () => {
  const CSSBlock = 'vendors-view';
  const { [RouteParams.VENDOR_ID]: vendorID } = useParams();

  const [vendor, setVendor] = useState<DemmiFS.Vendor>();
  const [_, setIsLoading] = useState(false);

  useEffect(() => {
    refreshVendor();
  }, [vendorID]);

  const refreshVendor = () => {
    if (vendorID) {
      setIsLoading(true);
      demmiRequest(getVendor(vendorID))
        .then(f => {
          setVendor(f);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <div className={`${CSSBlock}--details`}>
      {vendor && (
        <>
          <div className={`${CSSBlock}__title`}>Vendor Details</div>

          <DemmiCard title="Images" collapsed>
            <div className={`${CSSBlock}__image-grid`}>
              {vendor.images.map((image, i) => (
                <div className={`${CSSBlock}__image-item`} key={i}>
                  <div className={`${CSSBlock}__image-wrapper`}>
                    <AsyncImage vendorID={vendorID ?? ''} src={image.main} isVendor />
                  </div>
                </div>
              ))}
            </div>
          </DemmiCard>

          <VendorContactInfo CSSBlock={CSSBlock} vendor={vendor} onUpdated={refreshVendor} />
          <VendorDescription CSSBlock={CSSBlock} vendor={vendor} onUpdated={refreshVendor} />
          <VendorShop CSSBlock={CSSBlock} vendor={vendor} onUpdated={refreshVendor} />
        </>
      )}
    </div>
  );
};

export default VendorDetails;
