import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getProduct } from '@core/services/networkService/_product';
import { RouteParams } from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

const VendorInventoryDetails: React.FC = () => {
  const CSSBlock = 'vendor-inventory-details';
  const { [RouteParams.PRODUCT_ID]: productID } = useParams();

  const [_1, setProduct] = useState<DemmiFS.Product>();
  const [_2, setIsLoading] = useState(false);

  useEffect(() => {
    refreshProduct();
  }, [productID]);

  const refreshProduct = () => {
    if (productID) {
      setIsLoading(true);
      demmiRequest(getProduct(productID))
        .then(f => {
          setProduct(f);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return <div className={`${CSSBlock}`}></div>;
};

export default VendorInventoryDetails;
