import React, { useEffect, useState } from 'react';

import { updateVendorDescription } from '@core/services/networkService/_vendors';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInput from '@demmi-ui/Input';
import DemmiTextArea from '@demmi-ui/TextArea';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  CSSBlock: string;
  vendor: DemmiFS.Vendor;
  onUpdated: () => void;
}

const VendorDescription: React.FC<Props> = ({ CSSBlock, vendor, onUpdated }) => {
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const [shortDescription, setShortDescription] = useState<string | undefined>(
    vendor.shortDescription,
  );
  const [longDescription, setLongDescription] = useState<string | undefined>(
    vendor.longDescription.join('\n'),
  );

  useEffect(() => {
    setIsValid(
      !!(shortDescription && shortDescription.length > 0) &&
        !!(longDescription && longDescription.length > 0 && longDescription[0].length > 0),
    );
  }, [shortDescription, longDescription]);

  const onSave = async () => {
    if (isValid) {
      if (!vendor || !shortDescription || !longDescription) return;

      setIsLoading(true);
      await updateVendorDescription(
        vendor.docID,
        shortDescription,
        longDescription.split('\n'),
      ).then(() => onUpdated());

      setIsLoading(false);
      setEditMode(false);
    }
  };

  const onCancel = () => {
    setShortDescription(vendor.shortDescription);
    setLongDescription(vendor.longDescription.join('\n'));
    setEditMode(false);
  };

  const editModeActive = () => {
    return (
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Short Description</div>
          <div className={`${CSSBlock}__item-value`}>
            <DemmiInput type="text" value={shortDescription} onChangeValue={setShortDescription} />
          </div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Long Description</div>
          <div className={`${CSSBlock}__item-value`}>
            <DemmiTextArea value={longDescription} onChange={e => setLongDescription(e)} />
          </div>
        </div>
      </div>
    );
  };
  const editModeInactive = () => {
    return (
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Short Description</div>
          <div className={`${CSSBlock}__item-value`}>{shortDescription}</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Long Description</div>
          <div className={`${CSSBlock}__item-value`}>
            {longDescription &&
              longDescription.split('\n').map((text, i) => <span key={i}>{text}</span>)}
          </div>
        </div>
      </div>
    );
  };
  const cardContent = () => (editMode ? editModeActive() : editModeInactive());

  return (
    <DemmiCard
      title="Description"
      isLoading={isLoading}
      editMode={editMode}
      onClickEdit={() => setEditMode(true)}
      onCancel={onCancel}
      onSave={onSave}
      isValid={isValid}
      collapsed>
      {cardContent()}
    </DemmiCard>
  );
};

export default VendorDescription;
