import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Page from '@components/page/Page';

import {
  RouteParams,
  RoutingViews,
  getRouteFromPath,
} from '@core/services/routingService/routingService';

import { Unsubscribe } from 'firebase/firestore';
import { listenToDemmiSupport } from '@core/services/networkService/demmiSupport/demmiSupport';
import DemmiSupportPanelChat from './partials/_chat';
import { DemmiFS } from '@subhanhabib/demmilib';

const Support: React.FC = () => {
  const CSSBlock = 'demmi-support';
  const navigate = useNavigate();
  const { [RouteParams.SUPPORT_CHAT_ID]: chatID } = useParams();

  const [chats, setChats] = useState<DemmiFS.DemmiSupport[]>([]);
  const [filteredChats, setFilteredChats] = useState<DemmiFS.DemmiSupport[]>([]);
  const [selectedChat, setSelectedChat] = useState<DemmiFS.DemmiSupport | undefined>();

  useEffect(() => {
    const chat = chats.find(f => f.docID === chatID);
    if (chat) {
      setSelectedChat(undefined);
      setTimeout(() => {
        if (chat) setSelectedChat(chat);
      }, 50);
    }
  }, [chatID, chats]);

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;
    const cb = (r: DemmiFS.DemmiSupport[]) => {
      // Logger({ objs: { r } }, DemmiSupport);
      setChats(r);
    };
    listenToDemmiSupport(cb).then(u => (snapshotListener = u));

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, []);

  useEffect(() => setFilteredChats(chats), [chats]);

  const onClickChat = (index: number) => {
    const chat = filteredChats[index];
    setSelectedChat(undefined);
    navigate(
      getRouteFromPath(RoutingViews.SUPPORT_CHAT, {
        [RouteParams.SUPPORT_CHAT_ID]: chat.docID,
      }),
      {
        replace: true,
      },
    );
    setTimeout(() => {
      if (chat) setSelectedChat(chat);
    }, 50);
  };

  const filterChats = (filter: string) => {
    const filtered = chats.reduce((acc: DemmiFS.DemmiSupport[], cur: DemmiFS.DemmiSupport) => {
      if (
        filter &&
        !cur.title.toLowerCase().includes(filter.toLowerCase()) &&
        !cur.vendor?.name.toLowerCase().includes(filter.toLowerCase()) &&
        !cur.lastMessage?.text.toLowerCase().includes(filter.toLowerCase())
      )
        return acc;
      return [...acc, cur];
    }, []);
    setFilteredChats(filtered);
  };

  const sidebarButtons = () => {
    return filteredChats.map((chat, i) => (
      <div className={`${CSSBlock}__sidebar-button`} key={i}>
        <span className={`${CSSBlock}__sidebar-button-text`}>{`${chat.title}`}</span>
        {chat.lastMessage ? (
          <span className={`${CSSBlock}__sidebar-button-message`}>{chat.lastMessage.text}</span>
        ) : (
          <span className={`${CSSBlock}__sidebar-button-no-message`}>No messages yet</span>
        )}
        <span className={`${CSSBlock}__sidebar-button-vendor`}>{chat.vendor?.name}</span>
      </div>
    ));
  };

  return (
    <Page
      sidebarFixed={true}
      sidebarButtons={sidebarButtons()}
      sidebarActive={filteredChats.findIndex(f => selectedChat?.docID === f.docID)}
      sidebarTitle="Support Chats"
      sidebarOnClick={onClickChat}
      sidebarOnSearch={filterChats}
      sidebarSearchPlaceholder="Filter Support">
      {selectedChat && <DemmiSupportPanelChat chatID={selectedChat.docID} />}
    </Page>
  );
};

export default Support;
