import React from 'react';

import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';

import SidebarButton from './SidebarButton';

export interface SidebarTitleButton {
  faIcon: string;
  onClick: () => void;
}

interface _SelfManaged extends _Base {
  customButtons: JSX.Element[];
}

interface _ManagedButton {
  id: string;
  text: string;
  faIcon?: string;
}

interface _Managed extends _Base {
  buttons: _ManagedButton[];
}

interface _Base {
  fixed?: boolean;
  active: number;
  onClick: (index: number) => void;
  title: string;
  titleButtons?: (SidebarTitleButton | JSX.Element)[];
  onSearch?: (val: string) => void;
  searchPlaceholder?: string;
}
type Props = _Managed | _SelfManaged;

const isSelfManaged = (props: Props): props is _SelfManaged => {
  return 'customButtons' in props;
};

const isSidebatTitleButton = (b: SidebarTitleButton | JSX.Element): b is SidebarTitleButton => {
  return 'faIcon' in b;
};

const Sidebar: React.FC<Props> = props => {
  const CSSBlock = 'sidebar';
  const { fixed = true, active, title, titleButtons, onClick, onSearch, searchPlaceholder } = props;

  const isCustomButton = isSelfManaged(props);
  const buttons = isCustomButton ? props.customButtons : props.buttons;

  return (
    <>
      <div
        className={`${CSSBlock} ${fixed ? `${CSSBlock}--fixed` : ``} ${
          isCustomButton ? `${CSSBlock}--custom-buttons` : ``
        }`}>
        <div className={`${CSSBlock}__invisible-contents`}>
          <div className={`${CSSBlock}__invisible-contents-text`}>Expand</div>
        </div>
        <div className={`${CSSBlock}__header`}>
          <div className={`${CSSBlock}__title`}>
            {title}
            {titleButtons && (
              <div className={`${CSSBlock}__title-buttons`}>
                {titleButtons.map((titleButton, i) =>
                  isSidebatTitleButton(titleButton) ? (
                    <DemmiIconButton
                      faIcon={titleButton.faIcon}
                      onClick={titleButton.onClick}
                      size={IconButtonSize.SMALL}
                      key={i}
                    />
                  ) : (
                    titleButton
                  ),
                )}
              </div>
            )}
          </div>
          {onSearch && (
            <div className={`${CSSBlock}__search`}>
              <DemmiInput
                onChangeValue={onSearch}
                invert
                type="text"
                placeholder={searchPlaceholder}
              />
            </div>
          )}
        </div>

        <div className={`${CSSBlock}__buttons`}>
          {buttons.map((button, i) =>
            isCustomButton ? (
              <SidebarButton
                element={button as JSX.Element}
                active={i === active}
                key={i}
                onClick={() => onClick(i)}
              />
            ) : (
              <SidebarButton
                text={(button as _ManagedButton).text}
                faIcon={(button as _ManagedButton).faIcon}
                active={i === active}
                key={i}
                onClick={() => onClick(i)}
              />
            ),
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
