import { UserCredential } from 'firebase/auth';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface UserState {
  cred: UserCredential | undefined;
}

const initialState: UserState = {
  cred: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: builder => {
    builder.addCase('LOGOUT', state => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    clearCred: _ => {},
    storeCred: (_1, _2: PayloadAction<UserCredential>) => {},
  },
});

export const { clearCred, storeCred } = userSlice.actions;

export const selectUserCred = (state: RootState) => state.user.cred;

export default userSlice.reducer;
