import React from 'react';

const Home: React.FC = () => {
  const CSSBlock = 'home';

  return (
    <div className={CSSBlock}>
      <div className={`${CSSBlock}__title`}>Hey</div>
      <div className={`${CSSBlock}__description`}>Welcome to the Demmi Admin Portal.</div>
    </div>
  );
};

export default Home;
