import { formatRelative } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import DemmiIconButton, { IconButtonSize } from '@demmi-ui/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  isNotificationCustomer,
  NotificationCustomerWithVendor,
  NotificationVendorWithCustomer,
} from '@models/notifications.model';
import { DemmiFS, DemmiHelpers } from '@subhanhabib/demmilib';

interface Props {
  notification: NotificationCustomerWithVendor | NotificationVendorWithCustomer;
  CSSBlock: string;
  buttonText: string;
  buttonOnClick: () => void;
}

const NotificationItem: React.FC<Props> = ({ notification, CSSBlock: itemCSSBlock }) => {
  const CSSBlock = 'notification-item';
  const [readTimeout, setReadTimeout] = useState<NodeJS.Timeout>();

  const markAsDismissed = () => {};
  const markAsRead = () => {};
  // const markAsDismissed = () => updateNotificationDismissedStatus(notification.docID, true);
  // const markAsRead = () => updateNotificationReadStatus(notification.docID, true);
  const createHoverTimer = () => setReadTimeout(setTimeout(() => markAsRead(), 2000));
  const clearHoverTimer = () => clearTimeout(readTimeout);

  useEffect(() => {
    return () => clearHoverTimer();
  }, []);

  const addBadgeVendor = (vendor?: DemmiFS.Vendor) =>
    vendor ? (
      <div className={`${CSSBlock}__type-badge`}>
        Vendor: <span>{vendor.name}</span>
      </div>
    ) : (
      <></>
    );
  const addBadgeCustomer = (customer?: DemmiFS.User) =>
    customer ? (
      <div className={`${CSSBlock}__type-badge`}>
        Customer:{' '}
        <span>
          {customer.title} {customer.firstName} {customer.lastName}
        </span>
      </div>
    ) : (
      <></>
    );

  return (
    <div
      className={`${itemCSSBlock} ${CSSBlock} ${notification.read ? '' : ` ${CSSBlock}--new`}`}
      onMouseEnter={() => createHoverTimer()}
      onMouseLeave={() => clearHoverTimer()}>
      <div className={`${CSSBlock}__badge`}>
        <FontAwesomeIcon
          icon={DemmiFS.NotificationHelper.getNotificationTypeIcon(notification.type)}
        />
        {DemmiFS.NotificationHelper.getNotificationTypeText(notification.type)}
      </div>
      {isNotificationCustomer(notification)
        ? addBadgeVendor(notification.vendor)
        : addBadgeCustomer(notification.customer)}
      <div className={`${CSSBlock}__message`}>
        <ReactMarkdown className={`${CSSBlock}__text`} components={{ p: 'div' }}>
          notification.message
        </ReactMarkdown>
      </div>
      <div className={`${CSSBlock}__timestamp`}>
        {formatRelative(
          DemmiFS.getDateFromFirestore(notification.timestamp)!,
          new Date(),
          DemmiHelpers.Dates.formatRelativeOptions,
        )}
      </div>
      <div className={`${CSSBlock}__buttons`}>
        {/* <DemmiButton
          size={DemmiButtonSize.S}
          faIcon="fa-arrow-right-to-bracket"
          text={buttonText}
          onClick={() => buttonOnClick()}
        /> */}
        <DemmiIconButton
          faIcon="fa-xmark"
          onClick={() => markAsDismissed()}
          size={IconButtonSize.SMALL}
        />
      </div>
    </div>
  );
};

export default NotificationItem;
