import { Timestamp, Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';

import {
  getDemmiSupportChat,
  listenToDemmiSupportChatMessages,
  sendDemmiSupportMessage,
} from '@core/services/networkService/demmiSupport/demmiSupport';
import DemmiIconButton from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';

import SupportChatItem from './_chatItem';
import { DemmiFS } from '@subhanhabib/demmilib';

interface Props {
  chatID: string;
}

const DemmiSupportPanelChat: React.FC<Props> = ({ chatID }) => {
  const CSSBlock = 'demmi-support-chat';

  const [isLoading, setIsLoading] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const [chat, setChat] = useState<DemmiFS.DemmiSupport>();
  const [messages, setMessages] = useState<DemmiFS.DemmiSupportMessage[]>();
  const [inputText, setInputText] = useState('');

  const sendMessage = () => {
    setIsSendingMessage(true);
    const message: DemmiFS.FSDemmiSupportMessage = {
      text: inputText,
      isSenderDemmi: true,
      timestamp: Timestamp.fromDate(new Date()),
      read: false,
    };
    sendDemmiSupportMessage(chatID, message).then(() => setIsSendingMessage(false));
  };

  useEffect(() => {
    setMessages([]);
    setIsLoading(true);
    getDemmiSupportChat(chatID).then(c => {
      setChat(c);
      setIsLoading(false);
    });
  }, [chatID]);

  useEffect(() => {
    let snapshotListener: Unsubscribe | undefined;

    const cb = (r: DemmiFS.DemmiSupportMessage[]) => setMessages(r);
    listenToDemmiSupportChatMessages(chatID, cb).then(u => (snapshotListener = u));

    return () => {
      if (snapshotListener) snapshotListener();
    };
  }, [chatID]);

  return (
    <div className={`${CSSBlock} ${isLoading ? `${CSSBlock}--is-loading` : ``}`}>
      <div className={`${CSSBlock}__title`}>
        <div className={`${CSSBlock}__title-vendor-name`}>{chat?.vendor?.name}</div>
        {chat && chat.title}
      </div>
      <div className={`${CSSBlock}__content`}>
        {isLoading ? (
          <></>
        ) : messages ? (
          messages.length > 0 ? (
            messages.map((message, i) => <SupportChatItem key={i} message={message} />)
          ) : (
            <div className={`${CSSBlock}__no-messages`}>No messages (yet) ...</div>
          )
        ) : (
          <div className={`${CSSBlock}__no-messages`}>You&apos;ve created a new chat!</div>
        )}
      </div>
      <div
        className={`${CSSBlock}__input-area ${
          isSendingMessage ? `${CSSBlock}__input-area--is-loading` : ``
        }`}>
        <DemmiInput
          placeholder="Message"
          type="text"
          value={inputText}
          onChangeValue={setInputText}
        />
        <DemmiIconButton faIcon={'fa-send'} onClick={sendMessage} />
        <ElementLoadingIndicator isLoading={isSendingMessage} />
      </div>
      <ElementLoadingIndicator isLoading={isLoading} />
    </div>
  );
};

export default DemmiSupportPanelChat;
