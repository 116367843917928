export enum ThemeModeConstants {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}

export type ThemeMode =
  | ThemeModeConstants.LIGHT
  | ThemeModeConstants.DARK
  | ThemeModeConstants.SYSTEM;

export enum CSSVARS {
  THEME_BG_POS = '--theme-bg-pos',
}

export const setCSSVar = (cssVar: CSSVARS, value: number | string) =>
  document.documentElement.style.setProperty(cssVar, value.toString());

export interface RGBA {
  r: number;
  g: number;
  b: number;
  a: number;
}
export interface HSL {
  h: number;
  s: number;
  l: number;
}
