import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Page from '@components/page/Page';
import { getVendors } from '@core/services/networkService/_vendors';
import {
  RouteParams,
  RoutingViews,
  getRouteFromPath,
} from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';

import VendorView from './VendorView';
import { DemmiFS } from '@subhanhabib/demmilib';

const Vendors: React.FC = () => {
  const CSSBlock = 'vendors';
  const navigate = useNavigate();
  const { [RouteParams.VENDOR_ID]: vendorID } = useParams();

  const [vendors, setVendors] = useState<DemmiFS.Vendor[]>([]);
  const [filteredVendors, setFilteredVendors] = useState<DemmiFS.Vendor[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<DemmiFS.Vendor | undefined>();

  useEffect(() => {
    const vendor = vendors.find(f => f.docID === vendorID);
    if (vendor) {
      setSelectedVendor(undefined);
      setTimeout(() => {
        if (vendor) setSelectedVendor(vendor);
      }, 50);
    }
  }, [vendorID, vendors]);

  useEffect(() => onClickRefresh(), []);
  useEffect(() => setFilteredVendors(vendors), [vendors]);

  const onClickVendor = (index: number) => {
    const vendor = vendors[index];
    setSelectedVendor(undefined);
    navigate(
      getRouteFromPath(RoutingViews.VENDORS_DETAILS, {
        [RouteParams.VENDOR_ID]: vendor.docID,
      }),
      {
        replace: true,
      },
    );
    setTimeout(() => {
      if (vendor) setSelectedVendor(vendor);
    }, 50);
  };

  const filterVendors = (filter: string) => {
    const filtered = vendors.reduce((acc: DemmiFS.Vendor[], cur: DemmiFS.Vendor) => {
      if (filter && !cur.name.toLowerCase().includes(filter.toLowerCase())) return acc;
      return [...acc, cur];
    }, []);
    setFilteredVendors(filtered);
  };

  const onClickRefresh = () => {
    demmiRequest(getVendors()).then(f => setVendors(f));
    setSelectedVendor(undefined);
  };

  const sidebarButtons = () => {
    return filteredVendors.map((vendor, i) => (
      <div className={`${CSSBlock}__sidebar-button`} key={i}>
        <span className={`${CSSBlock}__sidebar-button-text`}>{vendor.name}</span>
        <span className={`${CSSBlock}__sidebar-button-address`}>
          {vendor.address?.address && Object.values(vendor.address!.address).join(', ')}
        </span>
      </div>
    ));
  };

  return (
    <Page
      sidebarFixed={!selectedVendor}
      sidebarButtons={sidebarButtons()}
      sidebarActive={vendors.findIndex(f => selectedVendor?.docID === f.docID)}
      sidebarTitle="Vendor"
      sidebarOnClick={onClickVendor}
      sidebarOnSearch={filterVendors}
      sidebarSearchPlaceholder="Filter Vendors">
      {selectedVendor && <VendorView vendor={selectedVendor} />}
    </Page>
  );
};

export default Vendors;
