import React from 'react';
import { Navigate, useOutlet } from 'react-router-dom';

import AppTheme from '@core/AppTheme';
import { RoutingViews, routingPaths } from '@core/services/routingService/routingService';
import { useAuth } from '@providers/authProvider';
import LoadingGlobal from '@components/loadingIntro/LoadingGlobal';

const UnprotectedRoute: React.FC = () => {
  const { user, isCheckingUser } = useAuth();
  const outlet = useOutlet();

  return isCheckingUser === true ? (
    <LoadingGlobal isMounted={true} />
  ) : user ? (
    <Navigate to={routingPaths[RoutingViews.HOME]} />
  ) : (
    <React.Fragment>
      <AppTheme isLogin={true} />
      {outlet}
    </React.Fragment>
  );
};
export default UnprotectedRoute;
