import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import RawDetails from '@components/rawDetails/RawDetails';
import { getProductVariants } from '@core/services/networkService/_product';
import { RouteParams } from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';
import { DemmiFS } from '@subhanhabib/demmilib';

const VendorInventoryVariants: React.FC = () => {
  const CSSBlock = 'vendor-inventory-variants';
  const { [RouteParams.PRODUCT_ID]: productID } = useParams();

  const [variants, setVariants] = useState<DemmiFS.ProductVariantWithRaw[]>();
  const [_, setIsLoading] = useState(false);

  useEffect(() => {
    refreshProduct();
  }, [productID]);

  const refreshProduct = () => {
    if (productID) {
      setIsLoading(true);
      demmiRequest(getProductVariants(productID))
        .then(v => {
          setVariants(v);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <div className={`${CSSBlock}`}>
      {variants &&
        variants.map((v, i) => <RawDetails title={v.name} key={i} data={v.raw} collapsed />)}
    </div>
  );
};

export default VendorInventoryVariants;
