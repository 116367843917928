import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getVendor, updateVendorAddress } from '@core/services/networkService/_vendors';
import { RouteParams } from '@core/services/routingService/routingService';

import { demmiRequest } from '@helpers/app.helper';

import VendorAddressInputs from './partials/_inputs';
import VendorAddressGeo from './partials/_geo';
import { DemmiFS } from '@subhanhabib/demmilib';

const VendorAddressView: React.FC = () => {
  const CSSBlock = 'vendors-view';
  const { [RouteParams.VENDOR_ID]: vendorID } = useParams();

  const [vendor, setVendor] = useState<DemmiFS.Vendor>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refreshVendor();
  }, [vendorID]);

  const onSave = async (address: DemmiFS.DemmiAddress) => {
    // if (isValid) {
    if (!vendor || !address) return;

    setIsLoading(true);
    await updateVendorAddress(vendor.docID, address).then(() => refreshVendor());

    setIsLoading(false);
    // }
  };

  const refreshVendor = () => {
    if (vendorID) {
      setIsLoading(true);
      demmiRequest(getVendor(vendorID))
        .then(f => {
          setVendor(f);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <div className={`${CSSBlock}--address`}>
      {vendor && (
        <>
          <VendorAddressGeo
            CSSBlock={CSSBlock}
            vendor={vendor}
            isLoading={isLoading}
            onSave={onSave}
          />
          <VendorAddressInputs
            CSSBlock={CSSBlock}
            vendor={vendor}
            isLoading={isLoading}
            onSave={onSave}
          />
        </>
      )}
    </div>
  );
};

export default VendorAddressView;
