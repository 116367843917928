import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCustomer } from '@core/services/networkService/_customer';
import { RouteParams } from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';

import CustomerCardBasicInfo from './partials/_cardBasicInfo';
import { DemmiFS } from '@subhanhabib/demmilib';

const CustomerDetails: React.FC = () => {
  const CSSBlock = 'customers-view';
  const { [RouteParams.CUSTOMER_ID]: customerID } = useParams();

  const [customer, setCustomer] = useState<DemmiFS.User>();

  useEffect(() => {
    refreshCustomer();
  }, [customerID]);

  const refreshCustomer = () => {
    if (customerID) {
      demmiRequest(getCustomer(customerID))
        .then(f => {
          setCustomer(f);
        })
        .catch(() => {});
    }
  };

  return (
    <div className={`${CSSBlock}--details`}>
      {customer && (
        <>
          <CustomerCardBasicInfo
            CSSBlock={CSSBlock}
            customer={customer}
            onUpdated={refreshCustomer}
          />

          {/* <CustomerCardAddress
            CSSBlock={CSSBlock}
            customer={customer}
            onUpdated={refreshCustomer}
          /> */}
        </>
      )}
    </div>
  );
};

export default CustomerDetails;
