import {
  DocumentData,
  QueryDocumentSnapshot,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

import { getDemmiDB } from '../../../firebase';
import { FSCollectionNames } from './networkService';
import { DemmiFS, DemmiHelpers, Logger } from '@subhanhabib/demmilib';

export const doesVendorExist = async (vendorID: string): Promise<boolean> => {
  const docSnap = await getDoc(doc(getDemmiDB(), FSCollectionNames.VENDORS, vendorID));
  return docSnap.exists();
};

export const getVendor = async (vendorID: string): Promise<DemmiFS.VendorWithRaw | undefined> => {
  const snap = await getDoc(doc(getDemmiDB(), FSCollectionNames.VENDORS, vendorID));

  if (snap.exists()) {
    const newVendor: DemmiFS.Vendor = snap.data() as DemmiFS.Vendor;
    if (!newVendor.shop?.openingHours) {
      newVendor.shop = {
        openingHours: DemmiHelpers.Vendor.EmptyOpeningHours,
      };
    }
    return {
      ...newVendor,
      raw: snap.data(),
      docID: snap.id,
    };
  } else {
    Logger({ messages: 'No such document!' }, getVendor);
  }
  return undefined;
};

export const getVendors = async (): Promise<DemmiFS.VendorWithRaw[]> => {
  const querySnapshot = await getDocs(FSCollections.Vendors);
  const vendors: DemmiFS.VendorWithRaw[] = [];
  querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
    vendors.push({
      docID: doc.id,
      raw: doc.data(),
      ...(doc.data() as DemmiFS.FSVendor),
    });
  });
  return vendors;
};

export const updateVendorDescription = async (
  vendorID: string,
  shortDescription: string,
  longDescription: string[],
): Promise<void> => {
  const exists = await doesVendorExist(vendorID);
  Logger({ objs: { exists } }, updateVendorDescription);
  if (!exists) return;

  const docRef = doc(FSCollections.Vendors, vendorID);
  return updateDoc(docRef, { shortDescription, longDescription });
};

export const updateVendorContactInfo = async (
  vendorID: string,
  contactNumber: string,
): Promise<void> => {
  const exists = await doesVendorExist(vendorID);
  Logger({ objs: { exists } }, updateVendorContactInfo);
  if (!exists) return;

  const docRef = doc(FSCollections.Vendors, vendorID);
  return updateDoc(docRef, { contactNumber });
};

export const updateVendorOpeningHours = async (
  vendorID: string,
  hours: DemmiFS.VendorOpeningHours,
): Promise<void> => {
  const exists = await doesVendorExist(vendorID);
  Logger({ objs: { exists } }, updateVendorOpeningHours);
  if (!exists) return;

  const docRef = doc(getDemmiDB(), FSCollectionNames.VENDORS, vendorID);
  return updateDoc(docRef, { ['shop.openingHours']: hours });
};

export const updateVendorAddress = async (
  vendorID: string,
  address: DemmiFS.DemmiAddress,
): Promise<void> => {
  const docRef = doc(FSCollections.Vendors, vendorID);
  return updateDoc(docRef, {
    address: {
      geo: address.geo,
      countryCode: address.countryCode,
      address: address.address,
    },
  });
};
