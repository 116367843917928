import React, { useState } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from 'react-router-dom';

import LoadingIntro from '@components/loadingIntro/LoadingIntro';
import Customers from '@pages/customers/Customers';
import CustomerDetails from '@pages/customers/details/CustomerDetails';
import Vendors from '@pages/vendors/Vendors';
import VendorDetails from '@pages/vendors/details/VendorDetails';
import VendorInventory from '@pages/vendors/inventory/VendorInventory';
import VendorInventoryDetails from '@pages/vendors/inventory/details/VendorInventoryDetails';
import VendorInventoryRaw from '@pages/vendors/inventory/raw/VendorInventoryRaw';
import VendorInventoryVariants from '@pages/vendors/inventory/variants/VendorInventoryVariants';
import VendorRaw from '@pages/vendors/raw/VendorRaw';
import VendorStripe from '@pages/vendors/stripe/VendorStripe';
import InviteCodes from '@pages/inviteCodes/InviteCodes';
import Login from '@pages/login/Login';

import { getDemmiAuth } from '../firebase';
import Home from '../pages/home/Home';
import AuthLayout from './AuthLayout';
import ProtectedRoute from './routeGuards/ProtectedRoute';
import UnprotectedRoute from './routeGuards/UnprotectedRoute';
import { RoutingViews, routingPaths } from './services/routingService/routingService';
import Orders from '@pages/orders/Orders';
import OrderDetails from '@pages/orders/details/OrderDetails';

import Support from '@pages/support/Support';
import SupportChatView from '@pages/support/details/SupportChatView';
import CustomerRaw from '@pages/customers/raw/CustomerRaw';
import VendorAddressView from '@pages/vendors/address/VendorAddress';
import CustomerAddressView from '@pages/customers/address/CustomerAddress';
import OrderRaw from '@pages/orders/raw/OrderRaw';

const AppCore: React.FC = () => {
  const [isLoadingIntro, setIsLoadingIntro] = useState(true);
  const [_, setIsLoadingContent] = useState(false);

  // Necessary because auth is always null before initialising,
  // and Firebase doesn't return a promise for initialisation.
  const getUser = () =>
    new Promise((resolve, reject) => {
      const unsubscribe = getDemmiAuth().onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AuthLayout />} loader={() => defer({ user: getUser() })}>
        <Route element={<UnprotectedRoute />}>
          <Route path="/" element={<Login />} />
        </Route>

        <Route path="/" element={<ProtectedRoute />}>
          <Route path={routingPaths[RoutingViews.HOME]} element={<Home />} />

          <Route path={routingPaths[RoutingViews.SUPPORT]} element={<Support />}>
            <Route path={routingPaths[RoutingViews.SUPPORT_CHAT]} element={<SupportChatView />} />
          </Route>

          <Route path={routingPaths[RoutingViews.VENDORS]} element={<Vendors />}>
            <Route path={routingPaths[RoutingViews.VENDORS_RAW]} element={<VendorRaw />} />
            <Route
              path={routingPaths[RoutingViews.VENDORS_ADDRESS]}
              element={<VendorAddressView />}
            />
            <Route path={routingPaths[RoutingViews.VENDORS_DETAILS]} element={<VendorDetails />} />
            <Route path={routingPaths[RoutingViews.VENDORS_STRIPE]} element={<VendorStripe />} />

            <Route
              path={routingPaths[RoutingViews.VENDORS_INVENTORY]}
              element={<VendorInventory />}>
              <Route
                path={routingPaths[RoutingViews.VENDORS_INVENTORY_RAW]}
                element={<VendorInventoryRaw />}
              />
              <Route
                path={routingPaths[RoutingViews.VENDORS_INVENTORY_DETAILS]}
                element={<VendorInventoryDetails />}
              />
              <Route
                path={routingPaths[RoutingViews.VENDORS_INVENTORY_VARIANTS]}
                element={<VendorInventoryVariants />}
              />
            </Route>
          </Route>

          <Route path={routingPaths[RoutingViews.CUSTOMERS]} element={<Customers />}>
            <Route
              path={routingPaths[RoutingViews.CUSTOMERS_DETAILS]}
              element={<CustomerDetails />}
            />
            <Route
              path={routingPaths[RoutingViews.CUSTOMERS_ADDRESS]}
              element={<CustomerAddressView />}
            />
            <Route path={routingPaths[RoutingViews.CUSTOMERS_RAW]} element={<CustomerRaw />} />
          </Route>

          <Route path={routingPaths[RoutingViews.ORDERS]} element={<Orders />}>
            <Route path={routingPaths[RoutingViews.ORDER_DETAILS]} element={<OrderDetails />} />
            <Route path={routingPaths[RoutingViews.ORDER_RAW]} element={<OrderRaw />} />
          </Route>

          <Route path={routingPaths[RoutingViews.INVITE_CODES]} element={<InviteCodes />}>
            <Route
              path={routingPaths[RoutingViews.INVITE_CODES_DETAILS]}
              element={<InviteCodes />}
            />
          </Route>
        </Route>
      </Route>,
    ),
  );

  return (
    <>
      <RouterProvider router={router} />

      {/* Main app loading */}
      {/* <LoadingIntro
        onAnimStart={() => setIsLoadingContent(true)}
        onAnimEnd={() => {}}
      /> */}
      {isLoadingIntro && (
        <LoadingIntro
          onAnimStart={() => setIsLoadingContent(true)}
          onAnimEnd={() => setIsLoadingIntro(false)}
        />
      )}
    </>
  );
};

export default AppCore;
