import { DocumentData } from 'firebase/firestore';
import React, { useState } from 'react';

import DemmiCard from '@demmi-ui/Card/Card';

interface Props {
  data: DocumentData;
  title?: string;
  collapsed?: boolean;
}

const RawDetails: React.FC<Props> = ({ data, title = 'FB Raw', collapsed }) => {
  const CSSBlock = 'raw-details';
  const [isDumpVisible, setIsDumpVisible] = useState(false);

  const hasIndent = (value: unknown) =>
    !(
      value === null ||
      value === undefined ||
      ['string', 'number', 'boolean'].includes(typeof value)
    );

  const keyView = (key: string) => {
    return <div className={`${CSSBlock}__item-key`}>{key}</div>;
  };

  const valueArrayView = (value: Array<unknown>) => {
    return (
      <div className={`${CSSBlock}__item-value`}>
        <ul>
          {value.map((item: unknown, i: number) => (
            <li key={i}>{valueView(i.toString(), item)}</li>
          ))}
        </ul>
      </div>
    );
  };

  const valueView = (key: string, value: unknown) => {
    if (value === undefined || value === null) {
      return <div className={`${CSSBlock}__item-value`}>null / undefined</div>;
    } else if (['string', 'number', 'boolean'].includes(typeof value)) {
      return (
        <>
          {keyView(key)}
          <div className={`${CSSBlock}__item-value`}>{value.toString()}</div>
        </>
      );
    } else if (typeof value === 'object') {
      return (
        <>
          {keyView(key)}
          {Object.entries(value).map(([k, v], i) => objectView(k, v, i, true))}
        </>
      );
    } else if (Array.isArray(value)) {
      return (
        <>
          {keyView(key)}
          {valueArrayView(value)}
        </>
      );
    }
  };

  const objectView = (key: string, value: unknown, index: number, indented = false) => {
    const indentSize = 4;
    const indent = indented ? `${indentSize}rem` : 0;
    return (
      <div
        className={`${CSSBlock}__item ${hasIndent(value) ? `${CSSBlock}__item--has-indent` : ``}`}
        key={index}
        style={{ marginLeft: indent }}>
        {valueView(key, value)}
      </div>
    );
  };

  return (
    <DemmiCard title={title} className={CSSBlock} collapsed={collapsed}>
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__dump ${isDumpVisible ? `${CSSBlock}__dump--visible` : ''}`}>
          <div
            className={`${CSSBlock}__dump-title`}
            onClick={() => setIsDumpVisible(!isDumpVisible)}>
            {isDumpVisible ? 'Hide' : 'Show'} RAW Dump
          </div>
          <pre>{JSON.stringify(data, null, 3)}</pre>
        </div>
        {Object.entries(data).map(([key, value], i) => objectView(key, value, i))}
      </div>
    </DemmiCard>
  );
};

export default RawDetails;
