import {
  QueryDocumentSnapshot,
  Unsubscribe,
  addDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

import { FSSubCollectionNames } from '../networkService';
import { parseToDemmiSupportChat, parseToDemmiSupportMessage } from './_helper';
import { demmiSupportChatMessagesQuery, demmiSupportQuery } from './_queries';
import { getVendor } from '../_vendors';
import { DemmiFS } from '@subhanhabib/demmilib';

export const listenToDemmiSupport = async (
  callback: (requests: DemmiFS.DemmiSupport[]) => void,
): Promise<Unsubscribe> => {
  return onSnapshot(demmiSupportQuery(), async querySnapshot => {
    const chats: DemmiFS.DemmiSupport[] = [];
    const vendorRequests: {
      [key: string]: Promise<DemmiFS.VendorWithRaw | undefined>;
    } = {};
    const messageRequests: {
      [key: string]: Promise<DemmiFS.DemmiSupportMessage | undefined>;
    } = {};
    querySnapshot.forEach(async (doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupport>) => {
      vendorRequests[doc.id] = getVendor(doc.data().vendorID);
      messageRequests[doc.id] = getLastDemmiSupportMessage(doc.id);
    });
    const messages = await Promise.all(Object.values(messageRequests));
    const vendors = await Promise.all(Object.values(vendorRequests));
    querySnapshot.forEach(async (doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupport>) => {
      const lastMessage = messages[Object.keys(messageRequests).indexOf(doc.id)];
      const vendor = vendors[Object.keys(vendorRequests).indexOf(doc.id)];

      chats.push({
        lastMessage,
        ...parseToDemmiSupportChat(doc, vendor),
      });
    });
    callback(chats);
  });
};

export const listenToDemmiSupportChatMessages = async (
  chatID: string,
  callback: (requests: DemmiFS.DemmiSupportMessage[]) => void,
): Promise<Unsubscribe> => {
  return onSnapshot(demmiSupportChatMessagesQuery(chatID), querySnapshot => {
    const messages: DemmiFS.DemmiSupportMessage[] = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<DemmiFS.FSDemmiSupportMessage>) => {
      const m = parseToDemmiSupportMessage(doc);
      if (m.timestamp) messages.push(m);
    });
    callback(messages.sort((a, b) => (a.timestamp! > b.timestamp! ? 1 : -1)));
  });
};

export const getLastDemmiSupportMessage = async (
  chatID: string,
): Promise<DemmiFS.DemmiSupportMessage | undefined> => {
  const q = query(
    FSCollections.DemmiSupportMessages([chatID, FSSubCollectionNames.DEMMI_SUPPORT_MESSAGES]),
    orderBy('timestamp', 'desc'),
    limit(1),
  );

  const querySnapshot = await getDocs(q).catch(_ => {
    // Logger(
    //   { objs: { chatID, e }, type: DemmiLogType.error },
    //   getLastDemmiSupportMessage
    // );
  });
  if (!querySnapshot || querySnapshot.empty) return undefined;
  return parseToDemmiSupportMessage(querySnapshot.docs[0]);
};

export const getDemmiSupportChat = async (
  chatID: string,
): Promise<DemmiFS.DemmiSupport | undefined> => {
  // Logger({ objs: { chatID } }, getDemmiSupportChat);
  const docSnap = await getDoc(doc(FSCollections.DemmiSupport, chatID));
  if (docSnap.exists()) {
    const vendor = await getVendor(docSnap.data().vendorID);
    return parseToDemmiSupportChat(docSnap, vendor);
  } else {
    // Logger(
    //   { messages: ['No such document!'], type: DemmiLogType.error },
    //   getDemmiSupportChat
    // );
  }
  return undefined;
};

export const sendDemmiSupportMessage = async (
  chatID: string,
  message: DemmiFS.FSDemmiSupportMessage,
): Promise<string> => {
  // Logger({ objs: { chatID, message } }, sendDemmiSupportMessage);
  const docRef = await addDoc(
    FSCollections.DemmiSupportMessages([chatID, FSSubCollectionNames.DEMMI_SUPPORT_MESSAGES]),
    message,
  );
  return docRef.id;
};

export const createDemmiSupportChat = async (chat: DemmiFS.FSDemmiSupport): Promise<string> => {
  // Logger({ objs: { chat } }, createDemmiSupportChat);
  const docRef = await addDoc(FSCollections.DemmiSupport, chat);
  return docRef.id;
};
