import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { RouteParams } from '@core/services/routingService/routingService';

import { demmiRequest } from '@helpers/app.helper';

import CustomerAddressInputs from './partials/_inputs';
import CustomerAddressGeo from './partials/_geo';
import { getCustomer, updateCustomerAddress } from '@core/services/networkService/_customer';
import { DemmiFS } from '@subhanhabib/demmilib';

const CustomerAddressView: React.FC = () => {
  const CSSBlock = 'customers-view';
  const { [RouteParams.CUSTOMER_ID]: customerID } = useParams();

  const [customer, setCustomer] = useState<DemmiFS.User>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refreshCustomer();
  }, [customerID]);

  const onSave = async (address: DemmiFS.DemmiAddress) => {
    // if (isValid) {
    if (!customer || !address) return;

    setIsLoading(true);
    await updateCustomerAddress(customer.docID, address).then(() => refreshCustomer());

    setIsLoading(false);
    // }
  };

  const refreshCustomer = () => {
    if (customerID) {
      setIsLoading(true);
      demmiRequest(getCustomer(customerID))
        .then(c => {
          setCustomer(c);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <div className={`${CSSBlock}--address`}>
      {customer && (
        <>
          <CustomerAddressGeo
            CSSBlock={CSSBlock}
            customer={customer}
            isLoading={isLoading}
            onSave={onSave}
          />
          <CustomerAddressInputs
            CSSBlock={CSSBlock}
            customer={customer}
            isLoading={isLoading}
            onSave={onSave}
          />
        </>
      )}
    </div>
  );
};

export default CustomerAddressView;
