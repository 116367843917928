import React from 'react';

import { faStyles } from '@demmi-ui/IconButton';

interface SelfManaged extends Base {
  element: JSX.Element;
}
interface Managed extends Base {
  text: string;
  faIcon?: string;
}

interface Base {
  active?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}
type Props = Managed | SelfManaged;

const isSelfManaged = (props: Props): props is SelfManaged => {
  return 'element' in props;
};

const SidebarButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ active, disabled, onClick, ...props }: Props, ref) => {
    const CSSBlock = 'sidebar-button';

    const el = isSelfManaged(props) ? (
      props.element
    ) : (
      <>
        {(props as Managed).faIcon && (
          <span className={`${CSSBlock}__icon`}>
            <i
              className={
                faStyles.find(item => (props as Managed).faIcon!.includes(item))
                  ? (props as Managed).faIcon
                  : `fa-solid ${(props as Managed).faIcon}`
              }></i>
          </span>
        )}
        <span className={`${CSSBlock}__text`}>{(props as Managed).text}</span>
      </>
    );

    return (
      <button
        className={`${CSSBlock} ${active ? `${CSSBlock}--active` : ``} ${
          isSelfManaged(props) ? `${CSSBlock}--custom-button` : ``
        }`}
        disabled={disabled}
        onClick={onClick}
        ref={ref}>
        {el}
      </button>
    );
  },
);

SidebarButton.displayName = 'SidebarButton';
export default SidebarButton;
