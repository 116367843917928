import {
  QueryDocumentSnapshot,
  Unsubscribe,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, DemmiLogType, Logger } from '@subhanhabib/demmilib';

import { parseToNotificationVendor } from './_helper';
import { notificationVendorQuery, notificationsVendorQuery } from './_queries';
import { getCustomer } from '../_customer';
import { NotificationVendorWithCustomer } from '@models/notifications.model';

export const listenToNotificationsVendor = async (
  callback: (notifications: NotificationVendorWithCustomer[]) => void,
): Promise<Unsubscribe> => {
  return onSnapshot(notificationsVendorQuery(), async querySnapshot => {
    const customerRequests: Promise<{
      notID: string;
      data: DemmiFS.User | undefined;
    }>[] = querySnapshot.docs.map(not => {
      return new Promise((resolve, reject) => {
        if (not.data().customerID)
          getCustomer(not.data().customerID!)
            .then(data => resolve({ notID: not.id, data }))
            .catch(error => reject(error));
        else reject();
      });
    });
    const customers = await Promise.all(customerRequests);

    const notifications: NotificationVendorWithCustomer[] = [];
    querySnapshot.forEach((doc: QueryDocumentSnapshot<DemmiFS.FSNotificationVendor>) => {
      notifications.push(
        parseToNotificationVendor(doc, customers.find(({ notID }) => notID === doc.id)?.data),
      );
    });
    callback(notifications);
  });
};
export const updateNotificationReadStatus = async (
  notificationID: string,
  read: boolean,
): Promise<void> => {
  const notification = await getDoc(notificationVendorQuery(notificationID));
  if (!notification) {
    Logger(
      {
        messages: ['Failed to find notification to push read update.'],
        objs: { notificationID, read },
        type: DemmiLogType.error,
      },
      updateNotificationReadStatus,
    );
    return;
  }

  const docRef = doc(FSCollections.NotificationsVendor, notificationID);
  return updateDoc(docRef, {
    read: read,
  });
};
export const updateNotificationDismissedStatus = async (
  notificationID: string,
  dismissed: boolean,
): Promise<void> => {
  const notification = await getDoc(notificationVendorQuery(notificationID));
  if (!notification) {
    Logger(
      {
        messages: ['Failed to find notification to push dismissed update.'],
        objs: { notificationID, dismissed },
        type: DemmiLogType.error,
      },
      updateNotificationDismissedStatus,
    );
    return;
  }

  const docRef = doc(FSCollections.NotificationsVendor, notificationID);
  return updateDoc(docRef, {
    dismissed: dismissed,
  });
};
