import React, { useEffect } from 'react';
import { Navigate, useLocation, useOutlet } from 'react-router-dom';

import PortalWrapper from '@components/portalWrapper/PortalWrapper';
import AppTheme from '@core/AppTheme';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';

import { selectGlobalContentMounted, setGlobalContentMounted } from '@core/store/appSlice/appSlice';
import { useAuth } from '@providers/authProvider';
import LoadingGlobal from '@components/loadingIntro/LoadingGlobal';
import { DemmiHooks } from '@subhanhabib/demmilib';

const ProtectedRoute: React.FC = () => {
  const isMounted = useAppSelector(selectGlobalContentMounted);
  const shouldRender = DemmiHooks.useDelayUnmount(isMounted, 100);
  const { user, isCheckingUser } = useAuth();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setGlobalContentMounted(true));
  }, [pathname]);

  const outlet = useOutlet();
  return isCheckingUser === true ? (
    <LoadingGlobal isMounted={true} />
  ) : user ? (
    <>
      <AppTheme isLogin={false} />
      <PortalWrapper isMounted={isMounted} shouldRender={shouldRender}>
        {outlet}
      </PortalWrapper>
    </>
  ) : (
    <Navigate to={'/'} />
  );
};
export default ProtectedRoute;
