import { User } from 'firebase/auth';
import { Suspense } from 'react';
import { Await, useLoaderData, useOutlet } from 'react-router-dom';

import LoadingAuth from '@components/loadingIntro/LoadingAuth';
import { AuthProvider } from '@providers/authProvider';

const AuthLayout = () => {
  const outlet = useOutlet();
  const userPromise = useLoaderData() as {
    user: Promise<User | null>;
  };

  return (
    <Suspense fallback={<LoadingAuth />}>
      <Await resolve={userPromise.user} errorElement={<div>Something went wrong</div>}>
        {user => {
          return <AuthProvider userData={user}>{outlet}</AuthProvider>;
        }}
      </Await>
    </Suspense>
  );
};

export default AuthLayout;
