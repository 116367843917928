import {
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
  addDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';
import { DemmiFS, Logger } from '@subhanhabib/demmilib';

export const isEmailAvailable = async (email: string): Promise<boolean> => {
  const q = query(FSCollections.InviteCodes, where('email', '==', email));
  const querySnapshot = await getDocs(q);
  return querySnapshot.empty;
};

export const isCodeAvailable = async (code: string): Promise<boolean> => {
  const q = query(FSCollections.InviteCodes, where('code', '==', code));
  const querySnapshot = await getDocs(q);
  return querySnapshot.empty;
};

export const createInviteCode = async (code: DemmiFS.FSInviteCode): Promise<string> => {
  const docRef = await addDoc(FSCollections.InviteCodes, {
    ...code,
    createdAt: Timestamp.fromDate(new Date()),
  });
  return docRef.id;
};

export const getInviteCodes = async (): Promise<DemmiFS.InviteCodeWithRaw[]> => {
  const querySnapshot = await getDocs(FSCollections.InviteCodes);
  const codes: DemmiFS.InviteCodeWithRaw[] = [];
  querySnapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
    codes.push({
      docID: doc.id,
      raw: doc.data(),
      ...(doc.data() as DemmiFS.FSInviteCode),
    });
  });
  return codes;
};

export const getInviteCode = async (id: string): Promise<DemmiFS.InviteCodeWithRaw | undefined> => {
  const docSnap = await getDoc(doc(FSCollections.InviteCodes, id));
  if (docSnap.exists()) {
    return {
      docID: docSnap.id,
      raw: docSnap.data(),
      ...(docSnap.data() as DemmiFS.FSInviteCode),
    };
  } else {
    Logger({ messages: 'No such document!' }, getInviteCode);
  }
  return undefined;
};

// export const isInviteCodeValid = async (inviteCode: string, email: string): Promise<boolean> => {
//   return getDoc(doc(db, FSCollectionNames.DEMMI, FSDocNames.DEMMI_LAUNCH)).then(doc => {
//     if (doc.exists()) {
//       console.log(doc.data());
//       let launch = doc.data() as FSDemmiLaunch;
//       const codeMatch = launch.inviteCodes.find(
//         code => code.code.toLowerCase() === inviteCode.toLowerCase() && code.email === email
//       );
//       return !!codeMatch;
//     }
//     return false;
//   });
// };
