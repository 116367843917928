import React, { useEffect, useState } from 'react';

import LoadingGlobal from '@components/loadingIntro/LoadingGlobal';

import DemmiButton from '@demmi-ui/Button';
import { useAuth } from '@providers/authProvider';

import CardAuthentication from './_partials/CardAuthentication';
import LoginError from './_partials/LoginError';
import { DemmiHooks } from '@subhanhabib/demmilib';

const Login: React.FC = () => {
  const CSSBlock = 'login';
  const [isVisible, setIsVisible] = useState(false);
  const { signIn } = useAuth();

  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [error, setError] = useState<string>();

  useEffect(() => {
    document.body.classList.add('theme-dark');
    setIsVisible(true);
    return () => setIsVisible(false);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const [isGlobalLoadingMounted, setIsGlobalLoadingMounted] = useState(false);
  const shouldRenderGlobalLoading = DemmiHooks.useDelayUnmount(isGlobalLoadingMounted, 200);
  useEffect(() => {
    setIsGlobalLoadingMounted(isLoading);
  }, [isLoading]);

  const onSubmit = async () => {
    setIsLoading(true);
    await signIn(inputEmail, inputPassword)
      .then(_ => {})
      .catch(e => setError(e));
    setIsLoading(false);
  };

  return (
    <>
      <div
        className={`${CSSBlock}__wrapper ${!isVisible ? `${CSSBlock}--hide` : ''} ${
          error ? `${CSSBlock}--error` : ''
        }`}>
        <CardAuthentication
          email={inputEmail}
          setEmail={setInputEmail}
          password={inputPassword}
          setPassword={setInputPassword}
          onSubmit={onSubmit}
        />
        <LoginError error={error} />
        <span className={`${CSSBlock}__submit-button`}>
          <DemmiButton onClick={() => onSubmit()} text={'Sign In'} />
        </span>
      </div>

      {shouldRenderGlobalLoading && <LoadingGlobal isMounted={isGlobalLoadingMounted} isLogin />}
    </>
  );
};

export default Login;
