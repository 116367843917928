import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  getFSDemmiAdminVendor,
  updateFSDemmiAdminVendor,
} from '@core/services/networkService/_demmi';
import { RouteParams } from '@core/services/routingService/routingService';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiInput from '@demmi-ui/Input';
import { demmiRequest } from '@helpers/app.helper';
import { FSDemmiAdminVendor } from '@models/demmi.model';
import { getVendor } from '@core/services/networkService/_vendors';
import { DemmiFS } from '@subhanhabib/demmilib';

const VendorStripe: React.FC = () => {
  const CSSBlock = 'vendor-stripe';
  const { [RouteParams.VENDOR_ID]: vendorID } = useParams();

  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const [vendor, setVendor] = useState<DemmiFS.Vendor>();
  const [vendorAdmin, setVendorAdmin] = useState<FSDemmiAdminVendor>();

  const [commission, setCommission] = useState<number>();
  const [stripeID, setStripeID] = useState<string>();

  useEffect(() => {
    if (vendorID) {
      setIsLoading(true);
      demmiRequest(getVendor(vendorID))
        .then(f => {
          setVendor(f);
          if (f) {
            getVendorAdminDetails(f);
          } else {
            setIsLoading(false);
          }
        })
        .catch(() => setIsLoading(false));
    }
  }, [vendorID]);

  useEffect(() => {
    setIsValid(
      !!stripeID && stripeID.length > 0 && !!commission && commission >= 0 && commission < 1,
    );
  }, [stripeID, commission]);

  const getVendorAdminDetails = (vendor: DemmiFS.Vendor) => {
    demmiRequest(getFSDemmiAdminVendor(vendor.docID))
      .then(data => {
        setIsLoading(false);
        setVendorAdmin(data);
        setCommission(data?.commissionRate);
        setStripeID(data?.stripeID);
      })
      .catch(() => setIsLoading(false));
  };

  const updateVendor = async () => {
    if (!vendor || !vendorID || !stripeID || !commission) return;

    setIsLoading(true);
    await updateFSDemmiAdminVendor(vendorID, {
      stripeID: stripeID,
      commissionRate: commission,
    }).then(() => {
      getVendorAdminDetails(vendor);
    });
  };

  const onSave = async () => {
    if (isValid) {
      await updateVendor();
      setEditMode(false);
    }
  };

  const onCancel = () => {
    setCommission(vendorAdmin?.commissionRate);
    setStripeID(vendorAdmin?.stripeID);
    setEditMode(false);
  };

  const editModeActive = () => {
    return (
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Commission Rate</div>
          <div className={`${CSSBlock}__item-value`}>
            <DemmiInput
              type="number"
              value={((commission ? commission : 0) * 100)?.toFixed(0)}
              onChangeValue={e => setCommission(parseInt(e) / 100)}
            />
          </div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Stripe ID</div>
          <div className={`${CSSBlock}__item-value`}>
            <DemmiInput type="text" value={stripeID} onChangeValue={e => setStripeID(e)} />
          </div>
        </div>
      </div>
    );
  };
  const editModeInactive = () => {
    return vendorAdmin ? (
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Commission Rate</div>
          <div className={`${CSSBlock}__item-value`}>{commission ? commission * 100 : '-'}%</div>
        </div>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Stripe ID</div>
          <div className={`${CSSBlock}__item-value`}>{stripeID ?? '-'}</div>
        </div>
      </div>
    ) : (
      <div className={`${CSSBlock}__content ${CSSBlock}__content--error`}>
        Payment information is not yet defined
      </div>
    );
  };
  const cardContent = () => (editMode ? editModeActive() : editModeInactive());

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>Stripe Payment Information</div>
      {vendor && (
        <DemmiCard
          title="Stripe"
          isLoading={isLoading}
          editMode={editMode}
          onClickEdit={() => setEditMode(!editMode)}
          onCancel={onCancel}
          onSave={onSave}
          isValid={isValid}>
          {cardContent()}
        </DemmiCard>
      )}
    </div>
  );
};

export default VendorStripe;
