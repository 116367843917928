import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ModalCreateInviteCode from '@components/modals/createInviteCode/ModalCreateInviteCode';
import Page from '@components/page/Page';
import { getInviteCodes } from '@core/services/networkService/_inviteCodes';
import {
  RouteParams,
  RoutingViews,
  getRouteFromPath,
} from '@core/services/routingService/routingService';
import { demmiRequest } from '@helpers/app.helper';

import InviteCodeView from './InviteCodeView';
import { DemmiFS } from '@subhanhabib/demmilib';

export const CSSBLOCK_SETTINGS = 'vendor-settings';

const InviteCodes: React.FC = () => {
  const CSSBlock = 'invite-codes';
  const navigate = useNavigate();
  const { [RouteParams.CUSTOMER_ID]: codeID } = useParams();

  const [codes, setCodes] = useState<DemmiFS.InviteCode[]>([]);
  const [filteredCodes, setFilteredCodes] = useState<DemmiFS.InviteCode[]>([]);
  const [selectedCode, setSelectedCode] = useState<DemmiFS.InviteCode | undefined>();

  useEffect(() => {
    const code = codes.find(f => f.docID === codeID);
    if (code) {
      setSelectedCode(undefined);
      setTimeout(() => {
        if (code) setSelectedCode(code);
      }, 50);
    }
  }, [codeID, codes]);

  useEffect(() => onClickRefresh(), []);
  useEffect(() => setFilteredCodes(codes), [codes]);

  const onClickCode = (index: number) => {
    const code = codes[index];
    setSelectedCode(undefined);
    navigate(
      getRouteFromPath(RoutingViews.INVITE_CODES_DETAILS, {
        [RouteParams.INVITE_CODE_ID]: code.docID,
      }),
      {
        replace: true,
      },
    );
    setTimeout(() => {
      if (code) setSelectedCode(code);
    }, 50);
  };

  const filterCodes = (filter: string) => {
    const filtered = codes.reduce((acc: DemmiFS.InviteCode[], cur: DemmiFS.InviteCode) => {
      if (filter && !cur.email.toLowerCase().includes(filter.toLowerCase())) return acc;
      return [...acc, cur];
    }, []);
    setFilteredCodes(filtered);
  };

  const onClickRefresh = () => {
    demmiRequest(getInviteCodes()).then(f => setCodes(f));
    setSelectedCode(undefined);
  };

  const sidebarButtons = () => {
    return filteredCodes.map((code, i) => (
      <div className={`${CSSBlock}__sidebar-button`} key={i}>
        <span className={`${CSSBlock}__sidebar-button-code`}>{`${code.code.substring(
          0,
          3,
        )}-${code.code.substring(3)}`}</span>
        <span className={`${CSSBlock}__sidebar-button-email`}>{code.email}</span>
      </div>
    ));
  };

  const sidebarTitleButtons = [
    { faIcon: 'fa-rotate-right', onClick: onClickRefresh },
    <ModalCreateInviteCode key={''} onClose={() => {}} onConfirm={() => {}} />,
  ];

  return (
    <Page
      sidebarFixed
      sidebarTitleButtons={sidebarTitleButtons}
      sidebarButtons={sidebarButtons()}
      sidebarActive={codes.findIndex(f => selectedCode?.docID === f.docID)}
      sidebarTitle="Invite Codes"
      sidebarOnClick={onClickCode}
      sidebarOnSearch={filterCodes}
      sidebarSearchPlaceholder="Filter Codes">
      {selectedCode && <InviteCodeView code={selectedCode} />}
    </Page>
  );
};

export default InviteCodes;
