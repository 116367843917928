import React from 'react';
import NotificationItem from './_notificationItem';

import { NotificationCustomerWithVendor } from '@models/notifications.model';

interface Props {
  notification: NotificationCustomerWithVendor;
  onNavigate: () => void;
}

const NotificationCustomerOrderNew: React.FC<Props> = ({ notification }) => {
  const CSSBlock = 'notification-order-new';

  const onClickView = () => {
    // navigateTransitioned({
    //   dispatch,
    //   navigate,
    //   to: routingPaths[RoutingViews.ORDERS_ALL],
    //   search: {
    //     [SearchParams.ORDER_ID]: notification.orderDocID!,
    //   },
    // });
    // onNavigate();
  };

  return (
    <NotificationItem
      notification={notification}
      CSSBlock={CSSBlock}
      buttonText="View"
      buttonOnClick={onClickView}></NotificationItem>
  );
};

export default NotificationCustomerOrderNew;
